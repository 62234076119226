import React, { useState, useEffect } from 'react';
import { Typography, Grid, Dialog, DialogContent, DialogActions, Button, Box, Card, CardContent, Backdrop, CircularProgress } from '@mui/material';
import MobileBottomNav from './MobileBottomNav'; // Import the bottom navigation component
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const BatchReceiveBulkOrders = () => {
    const [barcode, setBarcode] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [scannedOrder, setScannedOrder] = useState(false);
    const [errorSound, setErrorSound] = useState(false);
    const [successSound, setSuccessSound] = useState(false);
    const [orderCards, setOrderCards] = useState(JSON.parse(localStorage.getItem('orderCards')) || []);
    const [overrideRequest, setOverrideRequest] = useState(false);
    const [scannedBarcode, setScannedBarcode] = useState('');
    const [currentOrderId, setCurrentOrderId] = useState(null); // Store current order ID for override
    const [loading, setLoading] = useState(false); // Add loading state for API requests

    // Function to play sound from the correct path
    const playSound = (soundFilename) => {
        const audio = new Audio(require(`../../assets/sounds/${soundFilename}`));
        audio.play();
        return audio;
    };

    // Handle sound logic with queue
    useEffect(() => {
        let soundQueue = null;

        if (errorSound) {
            soundQueue = playSound('error.mp3');
            soundQueue.addEventListener('ended', () => {
                if (errorSound) soundQueue.play(); // Loop until state changes
            });
        } else if (successSound) {
            soundQueue = playSound('success.mp3');
            soundQueue.addEventListener('ended', () => {
                setSuccessSound(false); // Reset after playing once
            });
        }

        return () => {
            if (soundQueue) {
                soundQueue.pause();
                soundQueue.currentTime = 0; // Stop sound if state changes
            }
        };
    }, [errorSound, successSound]);

    useEffect(() => {
        const handleBarcodeScan = (e) => {
            if (e.key === 'Enter' && barcode.length > 0) {
                processBarcode(barcode);
                setBarcode(''); // Clear the barcode after processing
            } else if (e.key.length === 1) {
                setBarcode((prevBarcode) => prevBarcode + e.key); // Collect all keypresses into a full barcode
            }
        };

        window.addEventListener('keydown', handleBarcodeScan);
        return () => {
            window.removeEventListener('keydown', handleBarcodeScan);
        };
    }, [barcode]);

    const processBarcode = async (scannedBarcode) => {
        console.log("Scanned Barcode:", scannedBarcode);
        setScannedBarcode(scannedBarcode); // Save the scanned barcode

        // Check if it's a bulk order in either format: PWB{orderid}-2 or PWB{orderid}/2
        if (scannedBarcode.startsWith('PWB') && /^PWB\d+[-/]\d+$/.test(scannedBarcode)) {
            console.log("This is a Bulk Order");
            const orderNumber = scannedBarcode.match(/^PWB(\d+)[-/]/)[1]; // Correctly extract the order number
            await updateOrderStatus(orderNumber, 'Bulk Order');
        } else {
            // Handle invalid or unrecognized scans, no card for error, just the modal
            setModalTitle('ERROR');
            setModalMessage("Invalid Scan: Only bulk orders are supported.");
            setErrorSound(true); // Play error sound
            setOpenModal(true); // Show error modal instead of adding a card
        }
    };

    const updateOrderStatus = async (formattedOrderId, scanType, override = false) => {
        const token = sessionStorage.getItem("pfresh_bulk_jwt_token");
        const url = `https://order.petfresh.com.au/wp-json/wc/v3/update-order-status/${formattedOrderId}${override ? '?override=1' : ''}`;
        
        setLoading(true); // Start loading spinner

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
            
            if (response.ok) {
                // Successful request
                const { order_id, customer_first_name, customer_last_name, items_ordered } = data;
                addOrderCard(order_id, `${customer_first_name} ${customer_last_name}`, scanType, new Date().toLocaleString(), 'success', items_ordered);
                setSuccessSound(true); // Play success sound
            } else {
                // Handle different errors
                handleApiError(data, formattedOrderId, scanType);
            }
        } catch (error) {
            console.error('Error updating order:', error);
            setModalTitle('ERROR');
            setModalMessage('Failed to connect to the server.');
            setErrorSound(true); // Play error sound
            setOpenModal(true); // Show error modal
        } finally {
            setLoading(false); // Stop loading spinner after request is complete
        }
    };

    const handleApiError = (errorData, formattedOrderId, scanType) => {
        const { code, message } = errorData;
        if (code === "invalid_order") {
            setModalTitle('ERROR');
            setModalMessage("Invalid order ID.");
        } else if (code === "order_already_in_status") {
            setModalTitle(`Order is already changed`);
            setModalMessage(message);
            setCurrentOrderId(formattedOrderId); // Save the order ID for resend
            setOverrideRequest(true); // Enable the override button
        } else {
            setModalTitle('ERROR');
            setModalMessage(message);
        }
        setErrorSound(true); // Play error sound
        setOpenModal(true); // Show error modal instead of adding a card
    };

    const addOrderCard = (orderId, customerName, scanType, scanTime, status, itemsOrMessage) => {
        const newCard = {
            orderId,
            customerName,
            scanType,
            scanTime,
            status,
            items: itemsOrMessage
        };

        const updatedOrderCards = [newCard, ...orderCards]; // Add to the top of the array
        setOrderCards(updatedOrderCards);
        localStorage.setItem('orderCards', JSON.stringify(updatedOrderCards)); // Store in browser storage
        setScannedOrder(true); // There are now orders to show
    };

    const resendWithOverride = async () => {
        if (currentOrderId) {
            setLoading(true); // Start loading spinner for the retry
            const token = sessionStorage.getItem("pfresh_bulk_jwt_token");
            const url = `https://order.petfresh.com.au/wp-json/wc/v3/update-order-status/${currentOrderId}?override=1`;
    
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
    
                const data = await response.json();
    
                if (response.ok) {
                    // If the retry is successful, close the modal and update the card color to green
                    const { order_id, customer_first_name, customer_last_name, items_ordered } = data;
                    addOrderCard(order_id, `${customer_first_name} ${customer_last_name}`, 'Bulk Order', new Date().toLocaleString(), 'success', items_ordered);
                    setSuccessSound(true); // Play success sound
                    closeModal(); // Close the modal on success
                } else {
                    // If there's an error, show the error modal again
                    handleApiError(data, currentOrderId, 'Bulk Order');
                }
            } catch (error) {
                console.error('Error updating order:', error);
                setModalTitle('ERROR');
                setModalMessage('Failed to connect to the server.');
                setErrorSound(true); // Play error sound
                setOpenModal(true); // Show error modal
            } finally {
                setLoading(false); // Stop loading spinner
            }
        }
    };

    const closeModal = () => {
        setOpenModal(false);
        setErrorSound(false); // Stop any playing sound
    };

    return (
        <Grid container direction="column" alignItems="center" spacing={2} style={{ paddingBottom: '56px', paddingTop: '64px', minHeight: 'calc(100vh - 56px)' }}>
            <Grid item>
                <Typography variant="h4">Batch Receive</Typography>
            </Grid>
            
            {/* Display message for no orders scanned */}
            {!scannedOrder && orderCards.length === 0 && (
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%', marginTop: 'auto', marginBottom: 'auto' }}>
                    <GppMaybeIcon style={{ fontSize: '4rem', color: 'gray' }} />
                    <Typography variant="h6">Ready to Scan</Typography>
                    <Typography variant="body1">Please scan a Bulk Order, Click & Collect, or a Product to Recieve</Typography>
                </Grid>
            )}

            {/* Display scanned orders */}
            {orderCards.map((order) => (
                <Grid item xs={12} key={order.scanTime} style={{ width: '90%' }}>
                    <Card style={{ backgroundColor: order.status === 'success' ? '#77DD77' : '#FF0000', marginBottom: '16px' }}>
                        <CardContent>
                            <Typography style={{fontWeight: "bold"}} variant="h6">{order.status === 'success' ? `${order.scanType} #${order.orderId}` : `ERROR #${order.orderId}`}</Typography>
                            <Typography variant="body1">Customer: {order.customerName}</Typography>
                            <Typography variant="body2">Scanned at: {order.scanTime}</Typography>
                            <hr style={{borderColor: '#00000042', borderWidth: '0.5px'}} />
                            {order.status === 'success' ? (
                                Object.entries(order.items).map(([item, qty]) => (
                                    <Typography variant="body2" key={item} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{item}</span>
                                        <span style={{fontWeight: "bold"}}>{qty}</span>
                                    </Typography>
                                ))
                            ) : (
                                <Typography variant="body2">{order.items}</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            ))}

            {/* Backdrop for showing loading spinner */}
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" style={{ color: '#FFFFFF' }} /> {/* Spinner in white */}
            </Backdrop>

            {/* Modal for displaying error or override options */}
            <Dialog open={openModal} onClose={closeModal} fullScreen>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#FF0000', padding: '16px', color: 'white' }}>
                    <Typography variant="h6">{modalTitle}</Typography>
                </Box>
                <DialogContent>
                    <Typography variant="body1" align="center">{modalMessage}</Typography>
                    {overrideRequest && (
                        <Button onClick={resendWithOverride} variant="contained" color="primary" fullWidth style={{ marginTop: '16px', backgroundColor: '#FF0000' }}>
                            Change to Ready To Collect again
                        </Button>
                    )}
                </DialogContent>
                <DialogActions sx={{ position: 'absolute', bottom: 0, width: '100%' }}> {/* Fixed position at the bottom */}
                    <Button onClick={closeModal} color="primary" variant="contained" fullWidth style={{ padding: '16px', fontSize: '1.25rem', backgroundColor: '#FF0000', color: 'white' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <MobileBottomNav /> {/* Add the bottom navigation bar */}
        </Grid>
    );
};

export default BatchReceiveBulkOrders;