import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { useAuth0 } from '@auth0/auth0-react';

const Header = ({ anchorEl, handleMenu, handleClose, handleLogout }) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isAuth0User, setIsAuth0User] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuth0User(user.sub && user.sub.startsWith('auth0|'));

      // Check if the user has 'requirePasswordUpdate' set to true in metadata
      if (user['https://wms.neicha.com.au/app_metadata']?.requirePasswordUpdate === true) {
        // Force redirect to password reset
        //handleGeneratePasswordResetLink(true);
      }
    }
  }, [isAuthenticated, user]);

  const handleGeneratePasswordResetLink = async (forceRedirect = false) => {
    setLoading(true);
    try {
      const accessToken = sessionStorage.getItem('wms_access_token');

      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await fetch('https://wms-api.neicha.com.au/create-password-reset-ticket/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          user_id: user.sub,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const passwordResetUrl = data.ticket;

        if (forceRedirect) {
          window.location.href = passwordResetUrl;
        } else {
          alert(`Password reset link generated. You will be redirected to: ${passwordResetUrl}`);
          window.location.href = passwordResetUrl;
        }
      } else {
        const errorData = await response.json();
        alert(`Failed to generate password reset link: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error generating password reset link:', error);
      alert('Error generating password reset link');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppBar position="fixed" style={{ zIndex: 1300 }}>
      <Toolbar>
        <Logo style={{ height: 40, marginRight: 16 }} />
        <Typography
          variant="h6"
          style={{ color: 'white' }}
          noWrap
          sx={{
            display: {
              xs: 'none', // hide on extra-small screens
              sm: 'block', // show on small screens and up
            },
          }}
        >
          Warehouse Management System
        </Typography>
        <Typography
          variant="h6"
          style={{ color: 'white' }}
          noWrap
          sx={{
            display: {
              xs: 'block', // show on extra-small screens
              sm: 'none', // hide on small screens and up
            },
          }}
        >
          WMS
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle style={{ color: 'white' }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled>Welcome {user.name}</MenuItem>
            <MenuItem onClick={handleClose}>Account Details</MenuItem>
            {isAuth0User && (
              <MenuItem onClick={handleGeneratePasswordResetLink} disabled={loading}>
                Reset Password
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;