import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Fab,
  CircularProgress,
  Backdrop,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
} from '@mui/material';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Import the arrow icon
import MobileBottomNav from './MobileBottomNav'; // Import the bottom navigation component
import { useAuth0 } from '@auth0/auth0-react';

const StockTake = () => {
  const [stocktakeStarted, setStocktakeStarted] = useState(
    JSON.parse(localStorage.getItem('stocktakeStarted')) || false
  );
  const [barcode, setBarcode] = useState('');
  const [products, setProducts] = useState(
    JSON.parse(localStorage.getItem('scannedProducts')) || []
  );
  const [unscannedProducts, setUnscannedProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [stockCount, setStockCount] = useState(null); // Set default to null
  const [confirmStocktakeDialog, setConfirmStocktakeDialog] = useState(false);
  const [openStockCountDialog, setOpenStockCountDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productHistory, setProductHistory] = useState(null);
  const [productNotFound, setProductNotFound] = useState(false);
  const [stocktakeSuccessDialog, setStocktakeSuccessDialog] = useState(false);
  const [manualCountProduct, setManualCountProduct] = useState(null); // New state for manual count
  const [startTime, setStartTime] = useState(
    localStorage.getItem('stocktakeStartTime')
      ? new Date(localStorage.getItem('stocktakeStartTime'))
      : new Date()
  );
  const { user } = useAuth0();
  const selectedStore = JSON.parse(localStorage.getItem('selectedStore')) || '';
  const storeId = selectedStore.id;

  useEffect(() => {
    const handleBarcodeScan = (e) => {
      if (
        openStockCountDialog ||
        confirmStocktakeDialog ||
        productNotFound ||
        productHistory
      ) {
        return; // Do not detect barcodes when a dialog is open
      }

      // Start detecting barcodes when stocktake has started
      if (stocktakeStarted) {
        if (e.key === 'Enter' && barcode.length > 0) {
          processBarcode(barcode);
          setBarcode('');
        } else {
          // Handle faster barcode scanning by ensuring it's a valid barcode (e.g., typically 8-13 characters)
          if (e.key.length === 1 && e.key >= '0' && e.key <= '9') {
            setBarcode((prevBarcode) => prevBarcode + e.key);
          }
        }
      }
    };

    window.addEventListener('keypress', handleBarcodeScan);

    return () => {
      window.removeEventListener('keypress', handleBarcodeScan);
    };
  }, [
    barcode,
    stocktakeStarted,
    openStockCountDialog,
    confirmStocktakeDialog,
    productNotFound,
    productHistory,
  ]);

  const startStocktake = () => {
    setStocktakeStarted(true);
    const start = new Date();
    setStartTime(start);
    localStorage.setItem('stocktakeStarted', true);
    localStorage.setItem('stocktakeStartTime', start.toISOString());
  };

  const processBarcode = async (scannedBarcode) => {
    setLoading(true);
    const access_token = sessionStorage.getItem('wms_access_token');
    try {
      const response = await fetch(
        `https://wms-api.neicha.com.au/product?barcode=${scannedBarcode}`, // GET Product Data from Barcode
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      const productData = await response.json();

      if (productData.message === 'Product not found.') {
        setProductNotFound(true);
      } else {
        setCurrentProduct(productData);
        setOpenStockCountDialog(true);
      }
    } catch (error) {
      console.error('Error fetching product:', error);
      setProductNotFound(true);
    }
    setLoading(false);
  };

  const handleStockCountChange = (newCount) => {
    setStockCount(newCount);
  };

  const handleConfirmStockCount = () => {
    if (currentProduct || manualCountProduct) {
      const productToCount = manualCountProduct || currentProduct;

      setProducts((prevProducts) => {
        const existingProduct = prevProducts.find(
          (p) => p.sku === productToCount.sku
        );
        if (existingProduct) {
          existingProduct.qtyCounted += stockCount;
          existingProduct.history = existingProduct.history || [];
          existingProduct.history.push({
            count: stockCount,
            timestamp: new Date().toISOString(),
          });
        } else {
          prevProducts.push({
            ...productToCount,
            productname: productToCount.productname.replace(/&amp;/g, '&'),
            qtyCounted: stockCount,
            history: [
              { count: stockCount, timestamp: new Date().toISOString() },
            ],
          });
        }
        const updatedProducts = prevProducts.filter(
          (p) => p.qtyCounted !== null
        ); // Remove products with null qty
        localStorage.setItem('scannedProducts', JSON.stringify(updatedProducts));
        return updatedProducts;
      });
      setOpenStockCountDialog(false);
      setStockCount(null); // Reset stock count to null
      setManualCountProduct(null); // Reset manual count product
    }
  };

  const fetchUnscannedProducts = async () => {
    setLoading(true); // Show loading overlay
    const access_token = sessionStorage.getItem('wms_access_token');
    try {
      const response = await fetch(
        `https://wms-api.neicha.com.au/store-stock-items/${storeId}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      const storeProducts = await response.json();

      // Match unscanned products
      const scannedSKUs = products.map((p) => p.sku);
      const unscanned = storeProducts.filter(
        (product) => !scannedSKUs.includes(product.sku)
      );
      setUnscannedProducts(unscanned);
    } catch (error) {
      console.error('Error fetching unscanned products:', error);
    }
    setLoading(false); // Hide loading overlay
  };

  const handleConfirmStocktake = async () => {
    await fetchUnscannedProducts(); // Fetch unscanned products before confirmation
    setConfirmStocktakeDialog(true);
  };

  const handleManualCount = (product) => {
    setManualCountProduct(product);
    setCurrentProduct(product); // Set the product to current product
    setOpenStockCountDialog(true);
  };

  const finalizeStocktake = async () => {
    const access_token = sessionStorage.getItem('wms_access_token');
    const stocktakeItems = products.map((product) => ({
      sku: product.sku,
      qtyCounted: product.qtyCounted,
    }));

    const requestBody = {
      createdBy: user.name,
      storeid: storeId,
      items: stocktakeItems,
    };

    try {
      await fetch('https://wms-api.neicha.com.au/stocktake', {
        // Save Stocktake
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify(requestBody),
      });

      setConfirmStocktakeDialog(false);
      setProducts([]); // Reset products after confirmation
      localStorage.removeItem('scannedProducts');
      localStorage.removeItem('stocktakeStarted');
      localStorage.removeItem('stocktakeStartTime');
      setStocktakeSuccessDialog(true); // Show success dialog
    } catch (error) {
      console.error('Error finalizing stocktake:', error);
      // Optionally, handle error state here
    }
  };

  const handleSuccessDialogClose = () => {
    setStocktakeSuccessDialog(false);
    setStocktakeStarted(false);
  };

  const handleProductClick = (product) => {
    setProductHistory(product.history);
  };

  const handleDeleteScan = (product, index) => {
    const confirmed = window.confirm('Are you sure you want to delete this scan?');
    if (confirmed) {
      setProducts((prevProducts) => {
        const updatedProducts = prevProducts
          .map((p) => {
            if (p.sku === product.sku) {
              const updatedHistory = p.history.filter((_, i) => i !== index);
              const updatedQtyCounted = updatedHistory.reduce(
                (sum, h) => sum + h.count,
                0
              );
              return { ...p, qtyCounted: updatedQtyCounted, history: updatedHistory };
            }
            return p;
          })
          .filter((p) => p.qtyCounted > 0); // Remove products with 0 qty
        localStorage.setItem('scannedProducts', JSON.stringify(updatedProducts));
        return updatedProducts;
      });
      setProductHistory(null); // Close the dialog after deleting a scan
    }
  };

  const formatDateTime = (date) => {
    return date.toLocaleString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const renderNumberPad = () => {
    const handleNumberClick = (number) => {
      setStockCount((prevCount) =>
        prevCount === null ? number : parseInt(`${prevCount}${number}`, 10)
      );
    };

    const handleClear = () => {
      setStockCount(null);
    };

    return (
      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
          <Grid item xs={4} key={number}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleNumberClick(number)}
              style={{ padding: '20px', fontSize: '1.5rem' }}
            >
              {number}
            </Button>
          </Grid>
        ))}
        <Grid item xs={8}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleClear}
            style={{ padding: '20px', fontSize: '1.5rem' }}
          >
            CLEAR
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderUnscannedItems = () => {
    const groupedByDepartment = unscannedProducts.reduce((acc, product) => {
      const { departmentname } = product;
      if (!acc[departmentname]) {
        acc[departmentname] = [];
      }
      acc[departmentname].push(product);
      return acc;
    }, {});

    return Object.keys(groupedByDepartment).map((dept) => (
      <Box key={dept} style={{ marginBottom: '2em' }}>
        <Typography variant="h6" style={{ marginBottom: '1em' }}>
          {dept}
        </Typography>
        <Grid container spacing={2}>
          {groupedByDepartment[dept].map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.sku}>
              <Card
                style={{ cursor: 'pointer', position: 'relative', height: '100%' }}
                onClick={() => handleManualCount(product)}
              >
                <CardContent>
                  <Typography variant="h6">
                    {product.productname.replace(/&amp;/g, '&')}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    SKU: {product.sku}
                  </Typography>
                </CardContent>
                <ArrowForwardIosIcon
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '16px',
                    transform: 'translateY(-50%)',
                    color: '#9ba03b',
                  }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    ));
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start" // Align items to the left
      justifyContent={stocktakeStarted ? 'flex-start' : 'center'}
      spacing={2}
      style={{
        paddingBottom: '56px',
        paddingTop: '64px',
        minHeight: 'calc(100vh - 56px)',
      }} // Account for app bar
    >
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress sx={{ color: '#9ba03b' }} />
      </Backdrop>

      <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
        <Typography style={{ marginTop: '15px' }} variant="h4">
          Stock Take
        </Typography>
        {stocktakeStarted && (
          <Typography variant="body1">
            Time Started: {formatDateTime(startTime)}
          </Typography>
        )}
      </Grid>

      {!stocktakeStarted && (
        <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
          <Typography variant="h6">Would you like to start a stocktake?</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={startStocktake}
            style={{ marginTop: '20px' }}
          >
            Start Stocktake
          </Button>
        </Grid>
      )}

      {stocktakeStarted && (
        <>
          {products.length === 0 && (
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                width: '100%',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <GppMaybeIcon style={{ fontSize: '4rem', color: 'gray' }} />
              <Typography variant="h6">No Items have been counted</Typography>
              <Typography variant="body1">
                Please scan a barcode to start counting
              </Typography>
            </Grid>
          )}
          <div style={{ marginLeft: '2em', width: '85%', marginTop: '1em' }}>
            {/* Scanned Products List */}
            {products.reduce((acc, product) => {
              const lastDepartment = acc.length > 0 ? acc[acc.length - 1].departmentname : null;
              if (product.departmentname !== lastDepartment) {
                acc.push({ departmentname: product.departmentname, items: [product] });
              } else {
                acc[acc.length - 1].items.push(product);
              }
              return acc;
            }, []).map((department) => (
              <React.Fragment key={department.departmentname}>
                <Typography variant="h6" style={{ marginTop: '1em' }}>
                  {department.departmentname}
                </Typography>
                {department.items.map((product) => (
                  <Card
                    key={product.sku}
                    style={{ width: '100%', marginTop: '0.5em', cursor: 'pointer' }}
                    onClick={() => handleProductClick(product)}
                  >
                    <CardContent>
                      <Typography variant="h6">
                        {product.productname.replace(/&amp;/g, '&')}
                      </Typography>
                      <Typography variant="body1">Counted: {product.qtyCounted}</Typography>
                    </CardContent>
                  </Card>
                ))}
              </React.Fragment>
            ))}
          </div>

          {/* Scan History Dialog */}
          {productHistory && (
            <Dialog
              open={Boolean(productHistory)}
              onClose={() => setProductHistory(null)}
              fullScreen
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#9ba03b',
                  padding: '16px',
                  color: 'white',
                }}
              >
                <Typography variant="h6">
                  Count History: {currentProduct?.productname.replace(/&amp;/g, '&')}
                </Typography>
              </Box>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Count</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productHistory.map((entry, index) => {
                      const date = new Date(entry.timestamp);
                      return (
                        <TableRow key={index}>
                          <TableCell>{date.toLocaleDateString()}</TableCell>
                          <TableCell>{date.toLocaleTimeString()}</TableCell>
                          <TableCell>{entry.count}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleDeleteScan(currentProduct, index)}
                            >
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setProductHistory(null)}
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{
                    padding: '16px',
                    fontSize: '1.25rem',
                    backgroundColor: '#9ba03b',
                    color: 'white',
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {/* Product Not Found Dialog */}
          <Dialog
            open={productNotFound}
            onClose={() => setProductNotFound(false)}
            fullScreen
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#9ba03b',
                padding: '16px',
                color: 'white',
              }}
            >
              <Typography variant="h6">Product Not Found</Typography>
            </Box>
            <DialogContent>
              <Typography variant="body1" align="center" gutterBottom>
                This Product Could not be found, please write the following information down on a paper for investigation:
              </Typography>
              <Typography variant="h6" align="center">
                {barcode}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setProductNotFound(false)}
                color="primary"
                variant="contained"
                fullWidth
                style={{
                  padding: '16px',
                  fontSize: '1.25rem',
                  backgroundColor: '#9ba03b',
                  color: 'white',
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {products.length > 0 && (
            <Fab
              color="primary"
              variant="extended"
              style={{
                color: 'white',
                position: 'fixed',
                bottom: '80px',
                right: 0,
                width: '90%',
                padding: '2em',
                marginRight: '1.5em',
                backgroundColor: 'black',
              }}
              onClick={handleConfirmStocktake}
            >
              Save Stocktake
            </Fab>
          )}

          {/* Stock Count Dialog */}
          <Dialog
            open={openStockCountDialog}
            onClose={() => setOpenStockCountDialog(false)}
            fullWidth
            maxWidth="xs"
            fullScreen
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#9ba03b',
                padding: '16px',
                color: 'white',
              }}
            >
              <Typography variant="h6">
                Count: {manualCountProduct?.productname.replace(/&amp;/g, '&') || currentProduct?.productname.replace(/&amp;/g, '&')}
              </Typography>
            </Box>
            <DialogContent>
              <Typography variant="h5" align="center">
                {stockCount !== null ? stockCount : ''}
              </Typography>
              {renderNumberPad()}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmStockCount}
                color="primary"
                variant="contained"
                fullWidth
                style={{
                  padding: '16px',
                  fontSize: '1.25rem',
                  backgroundColor: stockCount === null ? 'red' : '#9ba03b',
                  color: 'white',
                }}
              >
                {stockCount === null ? 'Cancel Count' : 'Confirm Count'}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Confirm Stocktake Dialog */}
          <Dialog
            open={confirmStocktakeDialog}
            onClose={() => setConfirmStocktakeDialog(false)}
            fullScreen
          >
            <DialogTitle
              sx={{ backgroundColor: '#9ba03b', color: 'white' }}
            >
              Unscanned Items
            </DialogTitle>
            <DialogContent>
              {unscannedProducts.length > 0 ? (
                renderUnscannedItems()
              ) : (
                <Typography variant="body1">
                  All items have been scanned or manually counted.
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={finalizeStocktake}
                color="primary"
                variant="contained"
                style={{ backgroundColor: '#9ba03b', color: 'white' }}
              >
                Finalize Stocktake
              </Button>
              <Button
                onClick={() => setConfirmStocktakeDialog(false)}
                color="secondary"
                variant="contained"
                style={{ backgroundColor: 'lightgrey', color: 'black' }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          {/* Stocktake Success Dialog */}
          <Dialog
            open={stocktakeSuccessDialog}
            onClose={handleSuccessDialogClose}
            fullScreen
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#9ba03b',
                padding: '16px',
                color: 'white',
              }}
            >
              <Typography variant="h6">Stock Take Completed</Typography>
            </Box>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <CheckCircleIcon style={{ fontSize: '4rem', color: 'green' }} />
              <br />
              <Typography variant="h6" align="center">
                Stock Take was Created
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleSuccessDialogClose}
                color="primary"
                variant="contained"
                fullWidth
                style={{
                  padding: '16px',
                  fontSize: '1.25rem',
                  backgroundColor: '#9ba03b',
                  color: 'white',
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <MobileBottomNav /> {/* Add the bottom navigation bar */}
    </Grid>
  );
};

export default StockTake;