import React from 'react';
import { Button, Grid, Typography, Card, CardContent, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import CalculateIcon from '@mui/icons-material/Calculate';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SettingsIcon from '@mui/icons-material/Settings';

const MobileHome = () => {
  const navigate = useNavigate();

  const endpoints = [
    {
      path: '/mobile/stock-take',
      icon: <CalculateIcon />,
      title: 'Stock Take',
      description: 'Create a stock take for your store and update it in the WMS.'
    },
    {
      path: '/mobile/search-inventory',
      icon: <Inventory2Icon />,
      title: 'Search Inventory',
      description: 'Look up a product details and inventory accross all stores.'
    },
    {
      path: '/mobile/batch-receive-bulk-orders',
      icon: <QrCodeScannerIcon />,
      title: 'Batch Receive Bulk Orders',
      description: 'Receive bulk orders quickly using barcode scanning.'
    },
    {
      path: '/mobile/settings',
      icon: <SettingsIcon />,
      title: 'Settings',
      description: 'WMS Mobile User Settings.'
    }
  ];

  return (
    <Grid container direction="column" alignItems="center" spacing={2} style={{ paddingBottom: '56px' }}>
      <Grid item>
        <br /><br /><br /><h1>WMS Mobile Apps</h1>
      </Grid>

      {endpoints.map((endpoint, index) => (
        <Grid item key={index} style={{ width: '90%' }}>
          <Card onClick={() => navigate(endpoint.path)}>
            <CardContent>
              {endpoint.icon}
              <Typography variant="h5" component="div">
                {endpoint.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {endpoint.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate(endpoint.path)} variant="contained" color="primary">
                Open App
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default MobileHome;