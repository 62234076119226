import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Paper, CircularProgress, Typography, MenuItem } from '@mui/material';
import MobileBottomNav from './MobileBottomNav'; // Import the bottom navigation component
import axios from 'axios';

const MobileSettings = ({ stores }) => {
  const [selectedStore, setSelectedStore] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('selectedStore'));
    } catch (error) {
      return '';
    }
  });
  const [loading, setLoading] = useState(false);

  const handleStoreChange = (e) => {
    const selectedStoreId = e.target.value;
    const newSelectedStore = stores.find((store) => store.id === selectedStoreId);
    setSelectedStore(newSelectedStore);
  };

  const handleSaveStore = () => {
    localStorage.setItem('selectedStore', JSON.stringify(selectedStore));
    window.location.reload();
  };

  useEffect(() => {
    if (!stores.length) {
      const fetchStores = async () => {
        setLoading(true);
        try {
          const access_token = sessionStorage.getItem('wms_access_token');
          if (!access_token) {
            throw new Error('Access token not found in session storage');
          }

          const response = await axios.get('https://wms-api.neicha.com.au/stores', {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          });

          setSelectedStore(response.data[0] || '');
          setLoading(false);
        } catch (error) {
          console.error('Error fetching stores:', error);
          setLoading(false);
        }
      };
      fetchStores();
    }
  }, [stores]);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Paper style={{ paddingBottom: '56px', paddingTop: '64px', minHeight: 'calc(100vh - 56px)' }}>
      <Typography style={{"textAlign": "center"}} variant="h4">Settings</Typography>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <TextField
            label="Set Store"
            select
            value={selectedStore?.id || ''}
            onChange={handleStoreChange}
            fullWidth
          >
            {stores.map((store) => (
              <MenuItem key={store.id} value={store.id}>
                {store.storename}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveStore}
          >
            Save Store Selection
          </Button>
        </Grid>
        <MobileBottomNav /> {/* Add the bottom navigation bar */}
      </Grid>
    </Paper>
  );
};

export default MobileSettings;