import React from 'react';
import { Fab, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileBottomNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const handleNavChange = (newValue) => {
    if (newValue !== currentPath) {
      navigate(newValue);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end', // Align Home button to the right
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        padding: '8px 0',
        right: '10px', // Add some padding to the right
      }}
    >
      <Fab
        onClick={() => handleNavChange('/mobile/home')}
        sx={{
          backgroundColor: currentPath === '/mobile/home' ? '#9ba03b' : '#fff',
          color: currentPath === '/mobile/home' ? '#fff' : '#9ba03b',
          borderRadius: '50%',
        }}
      >
        <HomeIcon />
      </Fab>
    </Box>
  );
};

export default MobileBottomNav;